@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  font-family: 'Inter', sans-serif;
  
}

h1 {
  font-family: 'Poppins', sans-serif;
  font-size: 72px;
  font-weight: 600;
}

h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 48px;
  font-weight: 600;
}

h3 {
  font-family: 'Poppins', sans-serif;
  font-size: 32px;
  font-weight: 600;
}