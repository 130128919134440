ul {
  list-style: none;
}

a {
  text-decoration: none;
  color:#6E757C;
  font-size: 16px;
  font-weight: 500;
}

a:hover {
  color:black;
}

.navbar {
  background-color: #fff;
  padding: 40px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  min-height: 70px;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  max-height: 46px;
}

.nav-menu {
  background-color: #fff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 48px;
  margin-left: auto; /* add this line */
}

.nav-menu li:last-child {
  margin-left: auto;
}

.login-button {
  background-color: #8A64F8;
  color: #fff;
  border-radius: 15px;
  font-weight: 500;
  padding: 15px 20px;
}

.login-button:hover {
  background-color: #6e3ffa;
  color: #fff;
  text-decoration: none;
}

@media screen and (min-width: 865px) {
  .navbar {
    max-width: 1400px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 864px) {
  .hamburger {
    display: block;
    cursor: pointer;
  }

  .nav-menu li:last-child {
    margin: 16px 0;
  }

  .login-button {
    background-color: #fff;
    color: #120f0b
  }

  .bar {
    background-color: #120f0b;
    width: 20px;
    height: 3px;
    display: block;
    margin: 5px;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }

  .hamburger.show .bar:nth-child(2){
    opacity: 0;
  }
  .hamburger.show .bar:nth-child(1){
    transform: translateY(8px) rotate(45deg);
  }
  .hamburger.show .bar:nth-child(3){
    transform: translateY(-8px) rotate(-45deg);
  }

  .nav-menu{
    margin-top: 15px;
    position: absolute;
    flex-direction: column;
    gap: 0;
    top: 70px;
    left: -100%;
    text-align: start;
    width: 100%;
    transition: 0.7s ease-in-out;
  }

  .nav-menu.show {
    left: 0;
  }

  .nav-menu li {
    margin: 16px 0;
  }
}
/*.header-area {
    padding: 50px 0;
    margin-bottom: 20px;
  }
  
  .logo-nav img {
    max-width: 100px;
    padding-left: 20px;
  }
  
  .nav-area {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    margin: auto;
  }
  
  .nav-links {
    display: flex;
    padding-left: 75px;
    margin-right: auto;
    flex-grow: 1;
  }
  
  .nav-links ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .nav-links li {
    display: inline-block;
    margin-right: 10px;
    margin-right: 48px;
  }
  
  .nav-links a {
    font-weight: 600;
    color: rgb(97, 97, 122);
    font-size: 16px;
    line-height: 24px;
    text-decoration: none;
  }
  
  .nav-links a:hover {
    color: #000000;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
  
  .header-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .header-buttons-area {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
  }
  
  .header-buttons-area a {
    font-weight: 600;
    font-size: 16px;
    text-decoration: none;
    padding: 18px 20px;
  }
  
  .login {
    font-weight: 600;
    color: rgb(97, 97, 122);
  }
  
  .login:hover {
    color: #000000;
    font-weight: 600;
    font-size: 16px;
  }
  
  .signup {
    background-color: #8A64F8;
    color: #fff;
    border-radius: 15px;
  }
  
  .signup:hover {
    background-color: #6e3ffa;
    color: #fff;
    text-decoration: none;
  }
  
  .hamburger-menu {
    display: none;
    cursor: pointer;
  }
  
  .line {
    width: 30px;
    height: 3px;
    background-color: #000;
    margin: 5px 0;
  }
  
  .nav-menu {
    display: flex;
    align-items: center;
  }
  
@media (max-width: 1000px) {
  .nav-links {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    flex-direction: column;
  }

  .nav-links.show {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
  }

  .nav-links li {
    margin-bottom: 20px;
  }

  .nav-links a {
    font-weight: 600;
    color: rgb(97, 97, 122);
    font-size: 16px;
    line-height: 24px;
    text-decoration: none;
  }

  .nav-links a:hover {
    color: #000000;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  .nav-area {
    position: relative;
  }

  .hamburger-menu {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    display: block;
  }

  .line {
    width: 30px;
    height: 3px;
    background-color: #000;
    margin: 5px 0;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: auto;
  }

  .header-buttons-area {
    display: none;
  }

  .hamburger-menu {
    display: block;
  }
} */